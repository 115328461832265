function initWidgetsAllianceReseaux() {

    var idPanier = 'UO9TUFA';
    var idIntegration = '1659';

    var langue = 'fr';
    if ($('html').attr('lang') == 'en-GB') {
        langue = 'uk';
    }
    if ($('html').attr('lang') == 'nl-NL') {
        langue = 'nl';
    }

    var $elems = $('[data-code-ui]');

    if ($elems.length > 0) {

        $elems.each(function () {

            // WIDGET DANS LES PAGES
            var data = {
                idPanier: idPanier,
                idIntegration: idIntegration,
                langue: langue,
                ui: $(this).attr('data-code-ui'),
            };

            var widgetProduit = AllianceReseaux.Widget.Instance("Produit", data);

            if (document.location.hash) {
                var parts = document.location.hash.split('date=');
                if (parts.length > 1) {
                    var options = parts[1].split(',');

                    if (options.length == 3) {
                        var date = new Date(options[0] * 1000);

                        var day = (date.getDate());
                        if (day < 10) {
                            day = '0' + day;
                        }
                        var month = (date.getMonth() + 1);
                        if (month < 10) {
                            month = '0' + month;
                        }

                        var dateFormated = date.getFullYear() + '-' + month + '-' + day;
                        if (wp_debug) {
                            console.log('AUTO COMPLETE OPEN SYSTEM : ');
                            console.log('Date : ', dateFormated);
                            console.log('Durée : ', options[1]);
                            console.log('Nombre adulte : ', options[2]);
                            console.log('CODE UI : ', data.ui);
                        }
                        widgetProduit.PreApp("dispo.debut", dateFormated);
                        widgetProduit.PreApp("dispo.duree", parseInt(options[1]));
                        widgetProduit.PreApp("dispo.nbAdultes", parseInt(options[2]));
                        widgetProduit.PreApp("dispo.rechercher", true);

                        var $scrollToEl = $('#widget-opensystem');
                        var offsetScrollTo = $scrollToEl.offset();
                        $('html, body').animate({scrollTop: offsetScrollTo.top - 80}, 'slow');
                    }
                }
            }

            widgetProduit.Initialise();

        });
    }

    // PANIER
    var widgetPanier = AllianceReseaux.Widget.Instance("Panier", {
        idPanier: idPanier,
        idIntegration: idIntegration,
        langue: langue
    });
    widgetPanier.Initialise();
}


function updatePanierIcon() {
    $('#widget-panier .EnteteNbArticles').text($('#widget-panier .EnteteNbArticles').text().replace('(', '').replace(')', ''));
}

function checkIfAllianceInit() {
    if (typeof AllianceReseaux != 'undefined') {
        setTimeout(initWidgetsAllianceReseaux, 100);
    } else {
        setTimeout(checkIfAllianceInit, 100);
    }
}

checkIfAllianceInit();


// On ajoute un attribut aria-label sur le panier, pour pouvoir vocaliser son contenu.
// Le setTimeout est nécessaire pour donner le temps à opensystem d'injecter le texte dynamiquement dans le DOM,
// comme ça on peut ensuite le récupérer.

$(document).ready(function () {
    setTimeout(function () {
        var panier = $('#panier-ecran');
        var contenuPanier = $('#panier-ecran .ResumeBas .nbarticles').text();

        panier.attr('aria-label', contenuPanier);
    }, 2000);

});
