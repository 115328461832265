th_maps.onLoad(function () {

    th_maps.addMarkerIcon('default', {
        iconUrl: localizeParams.template_path + '/assets/images/markers/ico-marker-map-2x.png',
        shadowUrl: null,
        iconSize: [32, 32],
        iconAnchor: [16, 16]
    });

    th_maps.addMarkerIcon('hover', {
        iconUrl: localizeParams.template_path + '/assets/images/markers/ico-marker-map-hover-2x.png',
        shadowUrl: null,
        iconSize: [42, 42],
        iconAnchor: [21, 21]
    });
});

th_maps.init(thConfig.map.init);