function isIE(){
    if (navigator.userAgent.match(/trident/gi) || navigator.appName == 'Microsoft Internet Explorer') {
        return true;
    }
    return false;
}

function isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
}

function isNoHover(){
    if(isTouchDevice() && document.body.clientWidth <= thConfig.desktopBreakpoint){
        return true;
    }
    return false;
}

function isTabletPortraitOrSmalller(){
    if(document.body.clientWidth < thConfig.tabletPortraitBreakpoint){
        return true;
    }
    return false;
}

function isMobileOrSmaller(){
    if(document.body.clientWidth <= thConfig.mobileBreakpoint){
        return true;
    }
    return false;
}


// Englobe un élément HTML dans un autre
// Ex : wrap(document.querySelector('div.wrap_me'), document.createElement('div.wrapper'));
function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}