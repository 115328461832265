class DisclosureButtonTranscription {
    constructor(buttonNode) {
        this.buttonNode = buttonNode;
        this.controlledNode = false;

        const id = this.buttonNode.getAttribute('aria-controls');
        if (id) this.controlledNode = document.getElementById(id);

        if(this.controlledNode) {
            this.buttonNode.setAttribute('aria-expanded', 'false');
            this.hideContent();

            this.buttonNode.addEventListener('click', this.toggleExpand.bind(this));
        }
    }

    showContent() {
        this.controlledNode.classList.remove('u-is-hidden');
    }

    hideContent() {
        this.controlledNode.classList.add('u-is-hidden');
    }

    toggleExpand() {
        if (this.buttonNode.getAttribute('aria-expanded') === 'true') {
            this.buttonNode.setAttribute('aria-expanded', 'false');
            this.hideContent();
        } else {
            this.buttonNode.setAttribute('aria-expanded', 'true');
            this.showContent();
        }
    }
}