// ouverture de l'overlay des cards agenda sur le listingfma
function parseCardAgendaClickOverlay() {
    $('.js-card-open-overlay.card:not(.parsed)').on('click', function (e) {
        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href') + '?overlay';
        var overlay_id = 'overlay-sit';

        $('#' + overlay_id + ' .js-overlay-content').html('');
        $('#' + overlay_id + ' .loader').removeClass('u-is-hidden');

        $.get(sit_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('u-is-hidden');
            $('#' + overlay_id + ' .js-overlay-content').html(responseHTML);
            $('#' + overlay_id + ' .js-see-event').attr('href', elem.attr('href'));
        });

        th_overlay.open(overlay_id);

    }).addClass('parsed');
}

parseCardAgendaClickOverlay();