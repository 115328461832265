class History {
    selectors = {
        btn: '.js-history-nav-btn',
        btn_trigger: '.js-history-btn-trigger',
        counter: '.js-history-counter',
        card_container: '.js-history-results'
    };

    limit = 10;
    currentPage = null;
    postIDs = [];

    constructor() {

        if (!this.testLocalstorage()) {
            this.deactivate();
            return;
        }

        this.currentPage = parseInt(localizeParams.post_id);
        this.postIDs = this.loadFromLocalStorage();
        this.updateCounter();

        if (this.postIDs.length) {
            this.loadCards();
        // Pas de résultats
        } else {
            const card_container = document.querySelector(this.selectors.card_container);
            const alertHistoryLabel = card_container.dataset.alertHistoryLabel;
            card_container.innerHTML = `<li>
                                            <span role="alert" class="alert-instruction">${alertHistoryLabel}</span>
                                        </li>`;
        }

        if (!this.postIDs.includes(this.currentPage)) {
            this.postIDs.push(this.currentPage);
            this.saveToLocalStorage(this.postIDs);
        }
    }

    updateCounter() {
        const counter = document.querySelector(this.selectors.counter);
        const nbPosts = this.postIDs.length;
        counter.textContent = nbPosts;

        let ariaLabel = '';      // type "Historique de navigation (3 dernières pages vues)"
        const pllTranslations = localizeParams.pllTranslations;
        if(nbPosts === 1) {
            ariaLabel = `${pllTranslations.history_prefix} (${nbPosts} ${pllTranslations.history_suffix_singular})`;
        } else if(nbPosts > 1) {
            ariaLabel = `${pllTranslations.history_prefix} (${nbPosts} ${pllTranslations.history_suffix_plural})`;
        } else {
            ariaLabel = `${pllTranslations.history_prefix} (${pllTranslations.history_suffix_empty})`;
        }

        const btn_trigger = document.querySelector(this.selectors.btn_trigger);
        btn_trigger.setAttribute('aria-label', ariaLabel);
    }

    loadCards() {
        let formData = new FormData();
        formData.append('action', "history_render_card")
        formData.append('post_ids', JSON.stringify(this.postIDs));

        fetch(localizeParams.ajax_url, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                // En cas d'erreur
                if (!response.success) {
                    alert(response.data);
                    return;
                }

                const card_container = document.querySelector(this.selectors.card_container);
                card_container.innerHTML = response.data.full_html;

                var lazyLoadInstance = new LazyLoad({
                    elements_selector: ".lazy"
                });
            });
    }

    testLocalstorage() {
        const test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }

    deactivate() {
        const btn = document.querySelector(this.selectors.btn);
        btn.remove();
    }

    loadFromLocalStorage() {
        const contentString = localStorage.getItem('history_post_ids');
        if (contentString) {
            const contentJSON = JSON.parse(contentString);
            if (contentJSON) {
                return contentJSON;
            }
        }
        return [];
    }

    saveToLocalStorage() {

        if (this.postIDs.length > this.limit) {
            this.postIDs.shift();
        }

        localStorage.setItem('history_post_ids', JSON.stringify(this.postIDs));
    }
}

document.addEventListener("DOMContentLoaded", function () {
    const historyInstance = new History();
});