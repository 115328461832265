class ThViewMore {
    constructor(rootNode) {
        this.rootEl = rootNode;     // .js-view-more
        this.btnTrigger = this.rootEl.querySelector('[data-view-more-trigger]');
        this.btnTriggerLabel = this.rootEl.querySelector('[data-view-more-trigger-label]');

        if (this.btnTrigger) {
            const controlsId = this.btnTrigger.getAttribute('aria-controls');
            this.panel = document.getElementById(controlsId);

            setTimeout( () => {
                this.init();
            }, 100);
        }
    }

    init() {
        // Récupère les libellés du bouton type "Lire la suite" / "Réduire le contenu"
        this.openLabel = this.btnTrigger.dataset.viewMoreOpenLabel;
        this.closeLabel = this.btnTrigger.dataset.viewMoreCloseLabel;

        // État initial ouvert / fermé du panneau
        this.isOpen = this.btnTrigger.getAttribute('aria-expanded') === 'true';
        this.btnTrigger.addEventListener('click', this.onButtonClick.bind(this));

        // détecte si le contenu du paneau dépasse la hauteur max fixée en CSS
        if (isOverflowing(this.panel)) {
            this.rootEl.classList.add('is-overflow');
        } else {
            this.rootEl.classList.add('is-inactive');
        }

        // éléments masqués ou partiellement masqués qui dépassent de la zone visible à désactiver pour les SR
        this.hiddenElements = [];
        this.setHiddenElementsList(this.panel);
    }

    // Click sur le bouton "Lire la suite"
    onButtonClick(e) {
        (this.isOpen) ? this.close() : this.open();
    }

    // Ouverture du panneau
    open() {
        this.isOpen = true;
        this.rootEl.classList.add('is-open');
        this.panel.classList.add('is-open');
        this.btnTrigger.setAttribute('aria-expanded', true);
        this.btnTriggerLabel.textContent = this.openLabel;

        // recalcul de la hauteur réel du panneau à chaque ouverture, si window resize entre temps
        const totalHeight = convertPxToRem(this.panel.scrollHeight);
        this.panel.style.setProperty('--total-height', `${totalHeight}rem`);

        // Rend accessible les éléments précédemment masqués
        this.hiddenElements.forEach(el => {
            this.enableHiddenElements(el);
        });

        // focus sur le 1er élément nouvellement visible
        this.focusFirstElement();
    }

    // Fermeture du panneau
    close() {
        this.isOpen = false;
        this.rootEl.classList.remove('is-open');
        this.panel.classList.remove('is-open');
        this.btnTrigger.setAttribute('aria-expanded', false);
        this.btnTriggerLabel.textContent = this.closeLabel;

        // Masque les éléments en dehors du panneau
        this.hiddenElements.forEach(el => {
            this.disableHiddenElements(el);
        });
    }


    // Crée la liste des éléments masqués ou partiellement masqués qui dépassent de la zone visible à désactiver pour les SR
    setHiddenElementsList(panel) {
        // parse tous les descendants du panneau
        const descendants = [...panel.querySelectorAll('*')];

        descendants.forEach((el, index) => {
            const isInside = isInParent(el, panel);

            // Élément en dehors du parent et qui n'est pas un container défini explicitement avec data-view-more-no-aria-hidden et exclu de la liste
            if(!isInside && !el.hasAttribute("data-view-more-no-aria-hidden")) {
                this.hiddenElements.push(el);
                this.disableHiddenElements(el);
            }
        });

        //console.log('\nthis.hiddenElements ', this.hiddenElements);
    }


    // Rend inaccessible au clavier les descendants du panneau masqués ou partiellement masqués
    disableHiddenElements(el) {
        el.setAttribute('aria-hidden', 'true');

        // si élément focusable -> on doit également désactiver le focus pour le rendre inaccessible
        if (el.matches(focusableElementsArray)) {
            el.setAttribute('tabindex', '-1');
        }
    }


    // Quand on ouvre le panneau rend accessible les descendants précédemment masqués
    enableHiddenElements(el) {
        el.setAttribute('aria-hidden', 'false');
        el.removeAttribute('tabindex');

        // Focus et tabindex=-1 sur le 1er élément précédemment masqué (interactif ou non)
        /*if (this.firstChildFocus === null /!*&& el.matches(focusableElementsArray)*!/) {
            this.firstChildFocus = el;
            el.setAttribute('tabindex', '-1');
            el.focus();
        }*/

        /*const focusableElements = el.querySelectorAll(focusableElementsArray);
        focusableElements.forEach(focusableElement => {
            focusableElement.removeAttribute('tabindex');

            // Focus sur le 1er élément cliquable
            if(this.firstChildFocus === null) {
                this.firstChildFocus = focusableElement;
                this.firstChildFocus.focus();
            }
        });*/
    }


    // Quand on ouvre le panneau, focus sur le 1er élément nouvellement visible
    focusFirstElement() {
        let firstFocusableEl = this.hiddenElements[0];

        if (firstFocusableEl) {
            if(!firstFocusableEl.matches(focusableElementsArray)) {
                firstFocusableEl.setAttribute('tabindex', '0');
            }
            setTimeout( () => {
                firstFocusableEl.focus();
            }, 300);
        }
    }
}