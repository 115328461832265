// Menu des ancres synchronisé avec la position du scroll de la page
class ThAnchors {
    constructor(rootNode) {
        this.selectors = {
            anchorSection: '.js-anchor-section',
            anchorLink: '.js-anchor-link',
        };

        // Marge pour positionner les sections sous la barre de navigation
        this.scrollOffset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
        if (!this.scrollOffset) this.scrollOffset = 80;
        const strateInfo = document.querySelector('.strate-info');
        if (strateInfo) this.scrollOffset += strateInfo.offsetHeight;

        this.anchorLinks = document.querySelectorAll(this.selectors.anchorLink);
        this.anchorSections = document.querySelectorAll(this.selectors.anchorSection);

        this.init();
    }


    init() {
        // Click dans la nav et scroll jusqu'à une section
        this.anchorLinks.forEach(link => {
            link.addEventListener('click', this.onLinkClick.bind(this));
        });

        // pas d'observer sur ce projet
    }

    // Scroll smooth jusqu'à une section
    onLinkClick(e) {
        e.preventDefault();
        const link = e.currentTarget;
        const currentId = link.getAttribute('href');
        const currentSection = document.querySelector(currentId);

        if (currentSection) {
            // Enregistre la position courante du scroll avant le focus pour empêcher le défilement sur la section de sacader
            const currentScollX = window.scrollX;
            const currentScollY = window.scrollY;
            currentSection.focus();
            window.scrollTo(currentScollX, currentScollY);      // repart de la position d'origine du scroll au moment du click

            scrollToTarget(currentSection, -this.scrollOffset);
        }
    }
}
