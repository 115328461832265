(function () {
    // Scripts pour ajouter ou supprimer la classe "no-scroll"
    function onOverlayOpen(overlayId) {
        if (!document.body.classList.contains("no-scroll")) {
            document.body.classList.add("no-scroll")
        }

        if(overlayId === 'overlay-history') {
            const barreNavigation = document.querySelector('.js-barre-navigation');
            barreNavigation.classList.add('is-above-overlay');
        }
    }

    function onOverlayClose(overlayId) {
        if (document.body.classList.contains("no-scroll") && !$(th_overlay.selector_overlay_fiche_sit).hasClass('open')) {
            document.body.classList.remove("no-scroll");
        }

        if(overlayId === 'overlay-history') {
            const barreNavigation = document.querySelector('.js-barre-navigation');
            barreNavigation.classList.remove('is-above-overlay');
        }
    }

    th_overlay.addCallbackOpen(onOverlayOpen);
    th_overlay.addCallbackClose(onOverlayClose);
})();