// Création des players videos embed
class ThVideo {
    constructor(rootNode) {
        this.playerVideo = rootNode;
        this.btnPlay = rootNode.querySelector('.player-video__btn-play');
        this.embedContainer = rootNode.querySelector('.embed-container');
        this.iframe = rootNode.querySelector('iframe');

        if (this.embedContainer) this.init();
    }


    init() {
        // Bouton de lancement en overlay sur la vidéo -> Charge l'iframe du player au clic
        if (this.btnPlay) {
            // Ajout du param autoplay=1 à l'url pour charger l'iframe et lancer automatiquement la vidéo au clic
            let url = new URL(this.iframe.dataset.src);
            let params = new URLSearchParams(url.search);
            params.set('autoplay', '1');
            url.search = params.toString();
            this.iframe.dataset.src = url.toString();

            this.btnPlay.addEventListener('click', () => this.displayVideo(true));
            // Player embed sans overlay -> Charge l'iframe quand elle entre dans le viewport
        } else {
            this.initObserver();
        }
    }


    // Charge l'iframe du player quand elle entre dans le viewport
    initObserver() {
        if ('IntersectionObserver' in window) {
            this.observer = new IntersectionObserver(this.obCallback.bind(this), {
                rootMargin: '0px 0px 0px 0px',
            });
            this.observer.observe(this.playerVideo);
        } else {
            this.displayVideo();
        }
    }

    // Callback de l'intersection observer
    obCallback(entries) {
        entries.forEach(entry => {
            // entrée dans le viewport ->  charge l'iframe du player
            if (entry.isIntersecting) {
                this.displayVideo();
                this.observer.unobserve(this.playerVideo);
            }
        });
    }

    // Charge l'iframe en injectant l'attribut 'src' à partir de 'data-src'
    displayVideo(withClick = false) {
        this.iframe.src = this.iframe.dataset.src;
        delete this.iframe.dataset.src;
        this.playerVideo.classList.add('is-ready');

        // RGAA : focus sur le player
        if (withClick) {
            this.embedContainer.focus();
        }
    }
}
