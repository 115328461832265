// Gestion de la tabulation dans les modales
(function () {
    // éléments focalisables contenus dans la fenêtre modale
    // attr data-overlay-no-focusable : pour exclure certains éléments, par exemple ceux masqués en desktop
    const focusableElementsArray = [
        '[href]:not([data-overlay-no-focusable])',
        'button:not([disabled]):not([data-overlay-no-focusable])',
        'input:not([disabled]):not([data-overlay-no-focusable])',
        'select:not([disabled]):not([data-overlay-no-focusable])',
        'textarea:not([disabled]):not([data-overlay-no-focusable])',
        '[tabindex]:not([tabindex="-1"])',
    ];

    let focusableElements;
    let firstFocusableElement;
    let lastFocusableElement;

    // Boucle de la tabulation suivante / précédente au sein de la modale
    function onKeyDown(e) {
        if (e.key !== 'Tab') return;

        // shift + tab -> boucle sur le dernier élément focusable
        if (e.shiftKey) {
           if (document.activeElement === firstFocusableElement) {
                e.preventDefault();
                lastFocusableElement.focus();
            }
        // tab -> boucle sur le prémier élément focusable
        } else {
            if (document.activeElement === lastFocusableElement) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
        }
    }


    // Ouverture de la modale
    function onOverlayOpen(overlayId) {
        const overlay = document.getElementById(overlayId);

        // récupère une nodeList des éléments focalisables de l'overlay
        focusableElements = overlay.querySelectorAll(focusableElementsArray);
        firstFocusableElement= focusableElements[0];
        lastFocusableElement = focusableElements[focusableElements.length - 1];

        // return if no focusable element
        if (!firstFocusableElement) {
            return;
        }

        setTimeout(() => {
            firstFocusableElement.focus();

            // trapping focus inside the dialog
            overlay.addEventListener('keydown', onKeyDown);
        }, 100);
    }

    th_overlay.addCallbackOpen(onOverlayOpen);
})();