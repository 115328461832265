(function () {

    // Select des langues
    const langSelect = document.querySelector('.js-lang-select');
    if (langSelect) {
        langSelect.addEventListener('change', (e) => {
            window.location.href = langSelect.options[langSelect.selectedIndex].value;
        });
    }
})();
