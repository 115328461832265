var th_overlay = {
    selector_overlay: '.overlay',
    selector_overlay_shadow: '.shadow-overlay',

    callbackOpen: [],
    callbackClose: [],

    // RGAA
    isEscapeListening: true,
    lastOpenedId: [],
    focusElOnClose: {},
    noFocusOnPreviousOverlay: false,

    //
    overlayShadowNode: document.querySelector('.shadow-overlay'),

    init: function (settings) {
        if (settings) {
            $.each(settings, function (key, setting) {
                if (th_overlay[key]) {
                    th_overlay[key] = setting;
                }
            });
        }

        th_overlay.parseOverlayShadow();
        th_overlay.parse();
        th_overlay.openOnLoad();
        th_overlay.initKeyboard();      // RGAA on écoute la touche ESC pour fermer les overlays ouverts
    },



    // RGAA on écoute la touche ESC pour fermer les overlays ouverts
    initKeyboard: function() {
        if (th_overlay.isEscapeListening) {

            document.addEventListener('keydown', function (e) {
                if (th_overlay.lastOpenedId.length > 0 && e.key === "Escape") {
                    e.preventDefault();
                    e.stopPropagation();

                    // on ferme le dernier ouvert
                    th_overlay.close(th_overlay.lastOpenedId[th_overlay.lastOpenedId.length - 1]);
                }
            });
        }
    },


    // Ajoute une fonction de callback à l'ouverture de l'overlay
    addCallbackOpen: function (callback) {
        th_overlay.callbackOpen.push(callback);
    },


    // Ajoute une fonction de callback à la fermeture de l'overlay
    addCallbackClose: function (callback) {
        th_overlay.callbackClose.push(callback);
    },


    parseOverlayShadow: function () {
        th_overlay.overlayShadowNode.addEventListener('click', e => {
            e.preventDefault();
            th_overlay.close();
        });
    },


    parse: function () {
        document.querySelectorAll(th_overlay.selector_overlay).forEach((overlay) => {
            const overlayId = overlay.id;

            // boutons ou liens d'ouverture
            const triggersOpen = document.querySelectorAll('[data-overlay-open="' + overlayId + '"]:not(.parsed-overlay), a[href="#' + overlayId + '"]:not(.parsed-overlay)');
            triggersOpen.forEach((trigger) => {
                trigger.classList.add('parsed-overlay');
                if (trigger.tagName === 'A') trigger.setAttribute('type', 'button');

                trigger.addEventListener('click', e => {
                    e.preventDefault();

                    // empêche le click si aria-disabled
                    if (trigger.getAttribute('aria-disabled') === 'true') return;

                    // récupération de l'id à ouvrir via l'attribut data-overlay-open ou le href
                    let triggerId = trigger.dataset.overlayOpen;
                    if (!triggerId) {
                        triggerId = trigger.hash.substring(1);
                    }

                    // RGAA : On stocke l'element qui a ouvert l'overlay pour y revenir a sa fermeture
                    th_overlay.focusElOnClose[triggerId] = trigger;

                    th_overlay.open(triggerId);
                });
            });

            // boutons ou liens de fermeture
            const triggersClose = document.querySelectorAll('[data-overlay-close="' + overlayId + '"]:not(.parsed-overlay)');
            triggersClose.forEach((trigger) => {
                trigger.classList.add('parsed-overlay');

                trigger.addEventListener('click', e => {
                    e.preventDefault();

                    // récupération de l'id à ouvrir via l'attribut data-overlay-close uniquement
                    let triggerId = trigger.dataset.overlayClose;
                    if (!triggerId) {
                        triggerId = null;
                    }

                    th_overlay.close(triggerId);
                });
            });
        });
    },


    // Ouverture automatique si hash dans l'url correspondant à l'id d'un overlay
    openOnLoad: function () {
        if (window.location.hash) {
            const hash = window.location.hash.substring(1);
            const correspondingOverlay = document.getElementById(hash);

            if (correspondingOverlay && correspondingOverlay.classList.contains('overlay')) {
                th_overlay.open(hash);
            }
        }
    },


    open: function (overlayId, openShadow = true, doCallback = true) {

        // Referme les overlays déja ouverts sans fermer le shadow
        document.querySelectorAll(th_overlay.selector_overlay + '.is-open').forEach((overlay) => {
            th_overlay.close(overlay.id, false, true);
        });

        const overlayNode = document.getElementById(overlayId);
        if (overlayNode) {
            overlayNode.classList.add('is-open');

            const disableHash = overlayNode.dataset.overlayDisableHash;
            if (disableHash !== '' && disableHash !== 'true') {
                window.location.hash = overlayId;
            }

            if (openShadow) {
                th_overlay.overlayShadowNode.classList.add('is-open');
            }

            if (doCallback) {
                th_overlay.callbackOpen.forEach((fnCallback) => {
                    fnCallback(overlayId);
                });
            }

            // RGAA : On liste les overlays ouverts pour les fermer avec ESC
            th_overlay.lastOpenedId.push(overlayId);

            // RGAA : Focus de la croix de fermeture a l'ouverture de l'overlay
            th_overlay.setModalFocus(overlayId, overlayNode);
        }
    },

    close: function (overlayId = null, closeShadow = true, doCallback = true) {
        window.location.hash = " ";
        history.replaceState(null, null, ' ');

        if (overlayId) {
            if (th_overlay.lastOpenedId[th_overlay.lastOpenedId.length - 1] === overlayId) {
                th_overlay.lastOpenedId.pop();
            }

            const overlayNode = document.getElementById(overlayId);
            if (overlayNode) overlayNode.classList.remove('is-open');

        } else {
            //console.log('\nCLOOOOSE ALL' );
            document.querySelectorAll(th_overlay.selector_overlay + '.is-open').forEach((overlay) => {
                overlay.classList.remove('is-open');
            });
        }

        if (closeShadow) {
            th_overlay.overlayShadowNode.classList.remove('is-open');
        }

        if (doCallback) {
            th_overlay.callbackClose.forEach((fnCallback) => {
                fnCallback(overlayId);
            });
        }

        // RGAA : focus a nouveau sur l'élément qui a ouvert l'overlay
        th_overlay.setLastFocus(overlayId);
    },


    // RGAA : focus a nouveau sur l'élément qui a ouvert l'overlay
    setLastFocus : function(overlayId) {
        if (th_overlay.focusElOnClose[overlayId] && !th_overlay.noFocusOnPreviousOverlay) {
            setTimeout(function () {
                th_overlay.focusElOnClose[overlayId].focus();
            }, 250);
        }
    },


    // RGAA : focus a nouveau sur l'élément qui a ouvert l'overlay
    setModalFocus : function(overlayId, overlayNode) {
        setTimeout(function () {
            // Focus sur le champ de recherche dans l'overlay de recherche
            if (overlayId === 'overlay-search') {
                const inputSearch = overlayNode.querySelector('.js-search-input');
                if (inputSearch) inputSearch.focus();
            } else {
                const btnClose = overlayNode.querySelector('button[data-overlay-close]');
                if (btnClose) btnClose.focus();
            }
        }, 250);
    }

};
