//spécificités listing Agenda
parseCardAgendaClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.zoomControl.setPosition('topleft');
}

// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors: {
        mapoverlay: '.js-listing-overlay-map',
        //btn_loc: '.js-listing-btn-geoloc',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function (html) {
        parseCardAgendaClickOverlay();

        // gestion de l'affichage ou non du bouton pour voir la carte
        var nb_results = parseInt($(html).find(listingSitDefault.options.selectors.nb_results).text());

        if (nb_results > 100) {
            $('.js-listing-btn-showmap')
                .prop('aria-disabled', 'true');         // seulement aria-disabled, pas disable qui empêche le focus
        } else {
            $('.js-listing-btn-showmap')
                .removeAttr('aria-disabled');
            th_overlay.parse($('.js-listing-btn-showmap'));
        }
    }
});

// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors: {
        listing: '.js-listing-cpt',
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if (listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if (listingSitFMA.loaded) {
                listingSitFMA.initMapOverlay();
            }
        }, 850);
    }
});
