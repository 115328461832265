/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:  switch-checkbox.js
 *
 *   Desc:  Switch widget using input[type=checkbox] that implements ARIA Authoring Practices
 */

'use strict';

class CheckboxSwitch {
    constructor(rootNode) {
        this.rootNode = rootNode;
        this.input = rootNode.querySelector('input[type=checkbox]');
        this.input.addEventListener('focus', () => this.onFocus());
        this.input.addEventListener('blur', () => this.onBlur());
    }

    onFocus(e) {
        this.rootNode.classList.add('has-focus');
    }

    onBlur(e) {
        this.rootNode.classList.remove('has-focus');
    }
}
